<template>
    <div>


        <el-button type="primary" @click="Fun">导出文档</el-button>



        <!-- <button @click="ExportDocument2">导出文档</button> -->



        <!-- style=" position: fixed;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%); z-index: 999999;" -->

        <!-- padding: 30px 20px 0px 20px; -->


        <el-dialog :close-on-click-modal="false" title="导出" :visible.sync="show" width="calc(21cm + 40px)">


            <div id="btnBox">

                <div class="wordExport">
                    <h6 class="title">医疗废物数据阶段报告</h6>
                    <h6 class="subtitle">（独立账号）</h6>
                    <h6 class="number">报告编号：123123123</h6>
                    <h6 class="">机构名称：测试医院</h6>
                    <h6 class="">报告时间： 2023年8月2日14:41:20</h6>
                    <h6 class="">此段时间内，所关联机构/科室医疗废物处理数据如下：</h6>
                    <h6 class="">一、按医废类别统计 </h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <h6 class="">二、按科室类别统计</h6>
                    <h6 class="">1、自有科室：</h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>科室名称</td>
                            <td>启用时间</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3">合计</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <h6 class="">2、中转机构：</h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>机构名称</td>
                            <td>启用时间</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3">合计</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>

                    <h6 class="">（完）</h6>



                    <h6 class="title">医疗废物数据阶段报告</h6>
                    <h6 class="subtitle">（监管单位）</h6>
                    <h6 class="number">报告编号：123123</h6>
                    <h6 class="">单位名称：测试医院</h6>
                    <h6 class="">报告时间： 2023年8月2日14:41:42</h6>
                    <h6 class="">此段时间内，所监管机构医疗废物监管数据如下：</h6>
                    <h6 class="">一、按医废类别统计 </h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2">合计</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>

                    <h6 class="">二、按机构统计</h6>
                    <h6 class="">1、独立机构：</h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>医院名称</td>
                            <td>启用时间</td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>

                    <h6 class="">2、中转机构：</h6>

                    <table>
                        <tr>
                            <td></td>
                            <td>机构名称</td>
                            <td>启用时间</td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td rowspan="2"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>

                    <h6 class="">（完）</h6>

                </div>

            </div>


            <span slot="footer">
                <div class="buttons">
                    <!-- <el-button type="primary" @click="ExportDocument">导出文档</el-button>
                    <el-button type="primary" @click="ExportDocument1">导出文档1</el-button> -->
                    <el-button type="primary" @click="ExportDocument2">导出文档</el-button>

                </div>
            </span>


        </el-dialog>


    </div>
</template>


<script>
    import {
        exportWord,
        getBase64Sync
    } from '@/utils/exportFile.js'


    import $ from 'jquery'
    require('@/assets/js/jquery.wordexport')




    import FileSaver from 'file-saver'
    import htmlDocx from "html-docx-js/dist/html-docx"


    export default {

        data() {
            return {
                show: false
            }
        },
        created() {


        },
        methods: {
            Fun() {
                this.show = true
            },



            async ExportDocument() {

                var wordData = {
                    table1: {
                        name: "测试卫服",
                        number: "123456789",
                        time: "2023年8月1日-2023年8月1日",

                        class: [{
                            name: "感染类1",
                            num: "11",
                            weight: "11.01",
                        }, {
                            name: "损伤类1",
                            num: "21",
                            weight: "21.02",
                        }, {
                            name: "化学类1",
                            num: "31",
                            weight: "31.03",
                        }],

                        office: [{
                            name: "骨科",
                            time: "2023年8月1日11:59:15",
                            num: "11",
                            weight: "11.01",
                        }, {
                            name: "内科",
                            time: "2023年8月1日11:59:15",
                            num: "21",
                            weight: "21.02",
                        }, {
                            name: "脑科",
                            time: "2023年8月1日11:59:15",
                            num: "31",
                            weight: "31.03",
                        }],

                        officeAll: {
                            num: "99+",
                            weight: "99+",
                        },

                        dep: [{
                            name: "骨科医院",
                            time: "2023年8月1日11:59:15",
                            num: "11",
                            weight: "11.01",
                        }, {
                            name: "内科医院",
                            time: "2023年8月1日11:59:15",
                            num: "21",
                            weight: "21.02",
                        }, {
                            name: "脑科医院",
                            time: "2023年8月1日11:59:15",
                            num: "31",
                            weight: "31.03",
                        }],

                        depAll: {
                            num: "100+",
                            weight: "100+",
                        }

                    },


                    table2: {
                        name: "测试卫服",
                        number: "123456789",
                        time: "2023年8月1日-2023年8月1日",

                        class: [{
                            name: "感染类1",
                            num: "11",
                            weight: "11.01",
                        }, {
                            name: "损伤类1",
                            num: "21",
                            weight: "21.02",
                        }, {
                            name: "化学类1",
                            num: "31",
                            weight: "31.03",
                        }],

                        classAll: {
                            num: "99+",
                            weight: "99+",
                        },



                        office: [{
                                officeName: "骨科医院",
                                time: "2023年8月1日11:59:15",

                                children: [{
                                    name: "骨科化学类11111111111",
                                    num: "111",
                                    weight: "11.01",
                                }, {
                                    name: "骨科药物类11",
                                    num: "222",
                                    weight: "222.01",
                                }, ]
                            },

                            {
                                officeName: "内科医院",
                                time: "2023年8月1日11:59:15",

                                children: [{
                                    name: "内科化学类1123",
                                    num: "333",
                                    weight: "3333",
                                }, {
                                    name: "内科药物类12仨啊但是",
                                    num: "444",
                                    weight: "444444444",
                                }, {
                                    name: "内科药物类1多大阿达阿达阿达是的",
                                    num: "444",
                                    weight: "444444444",
                                }, ]
                            },

                        ],





                        dep: [{
                                depName: "中转机构111",
                                time: "2023年8月1日11:59:15",

                                children: [{
                                    name: "骨科化学类11111111111",
                                    num: "111",
                                    weight: "11.01",
                                }, {
                                    name: "骨科药物类11",
                                    num: "222",
                                    weight: "222.01",
                                }, ]
                            },

                            {
                                depName: "中转机构222",
                                time: "2023年8月1日11:59:15",

                                children: [{
                                    name: "内科化学类1123",
                                    num: "333",
                                    weight: "3333",
                                }, {
                                    name: "内科药物类12仨啊但是",
                                    num: "444",
                                    weight: "444444444",
                                }, {
                                    name: "内科药物类1多大阿达阿达阿达是的",
                                    num: "444",
                                    weight: "444444444",
                                }, ]
                            },

                        ],

                    },

                }


                // wordData.src = await getBase64Sync(this.url)
                exportWord('/template.docx', wordData, '医废数据报告.docx', [100, 100])

            },

            ExportDocument1() {


                // $("#btnBox").css("background-color", "yellow")

                setTimeout(() => {

                    $("#btnBox .wordExport").wordExport("医废数据报告")

                }, 1000)

            },







            ExportDocument2() {

                // 界面样式 需放到 数据流 里


                let contentHtml = document.getElementById("btnBox").innerHTML
                let content = `<!DOCTYPE html><html>
 <head>
 <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

<style>

.wordExport {
       /* // border: 1px red solid; */
       box-sizing: border-box;
       width: 21cm;
       height: 29.7cm;
       display: inline-block;
/*       // transform: scale(0.7);
       // padding: 2.5cm; */
       background-color: white;
        overflow: scroll;
   } 
   
   h6{
   
   width: 100%;
   display: inline-block;
   
   /* // border: 1px red solid; */
   box-sizing: border-box;
   
   text-align: left;
   font-size: 16px;
   padding: 0.1cm;
   }
      
       
  .title{
       text-align: center;
       font-size: 18px;
       font-weight: 600;
   }
  .subtitle{
       text-align: center;
       font-size: 18px;
   }
   .number{
       text-align: right;
   }
   
   table{
       
      border-collapse: collapse;  /* 合并边框 */
      width: 100%;  /* 设置表格宽度为100% */
      margin-bottom: 0.5cm;
   }
   
   th,  td {
       border: 1px solid black;  /* 设置单元格边框为1px黑色实线 */
       padding: 10px;  /* 设置单元格内边距为10px */
       text-align: center;  /* 设置单元格中的文字居中显示 */
   }
    th {
        background-color: lightgray;  /* 设置表头背景颜色为浅灰色 */
   }

</style>

 </head>
 <body>
 ${contentHtml}
 </body>
 </html>`;

                let converted = htmlDocx.asBlob(content);

                // console.log(converted);

                FileSaver.saveAs(converted, '医废数据报告.docx');

            },



        }
    }
</script>


<style scoped>
    .wordExport {
        /* // border: 1px red solid; */
        box-sizing: border-box;
        width: 21cm;
        height: 29.7cm;
        display: inline-block;
        /*       // transform: scale(0.7);
       // padding: 2.5cm; */
        background-color: white;
        overflow: scroll;



    }

    h6 {

        width: 100%;
        display: inline-block;

        /* // border: 1px red solid; */
        box-sizing: border-box;

        text-align: left;
        font-size: 16px;
        padding: 0.1cm;

    }


    .title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .subtitle {
        text-align: center;
        font-size: 18px;
    }

    .number {
        text-align: right;
    }

    table {

        border-collapse: collapse;
        /* 合并边框 */
        width: 100%;
        /* 设置表格宽度为100% */
        margin-bottom: 0.5cm;
    }

    th,
    td {
        border: 1px solid black;
        /* 设置单元格边框为1px黑色实线 */
        padding: 10px;
        /* 设置单元格内边距为10px */
        text-align: center;
        /* 设置单元格中的文字居中显示 */
    }

    th {
        background-color: lightgray;
        /* 设置表头背景颜色为浅灰色 */
    }
</style>