import { render, staticRenderFns } from "./outWord - 副本.vue?vue&type=template&id=d5e78288&scoped=true&"
import script from "./outWord - 副本.vue?vue&type=script&lang=js&"
export * from "./outWord - 副本.vue?vue&type=script&lang=js&"
import style0 from "./outWord - 副本.vue?vue&type=style&index=0&id=d5e78288&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5e78288",
  null
  
)

export default component.exports